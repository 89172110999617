import React, { useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-8.svg";

const Subheading = tw(SubheadingBase)`mb-4 text-center`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(
  SectionDescription
)`font-ddin w-full font-bold text-black text-center`;

const Column = tw.div`flex flex-col items-center`;
const HeaderContent = tw.div``;

const FAQSContainer = tw.dl`mt-12 max-w-4xl relative `;
const FAQ = tw.div`cursor-pointer select-none mt-5 px-8 sm:px-10 py-5 sm:py-4 rounded-lg text-black hover:text-gray-900 bg-gray-300 transition duration-300`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`font-ddin text-lg lg:text-xl font-bold`;
const QuestionToggleIcon = motion(styled.span`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`);
const Answer = motion(
  tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed text-black`
);
const DecoratorChevronDown = styled(ChevronDownIcon)`
  ${tw`fill-current`}
  color: ${({ color }) => color};
`;
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-56 w-56 opacity-15 transform translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

export default ({
  // subheading = "FAQS",
  heading = "PREGUNTAS FRECUENTES",
  description = "Aquí te dejamos algunas de las preguntas más comunes entre nuestros clientes. Recuerda que estamos siempre a tu disposición para resolver cualquier duda y ayudarte a entender mejor nuestros servicios.",
  faqs = [
    {
      question: "¿Cuáles son los servicios que ofrecemos?",
      answer:
        "Nos especializamos en comunicación digital para marcas y empresas, ofreciendo un enfoque integral para cubrir todas tus necesidades. Nuestro equipo está capacitado para abordar diversas áreas de comunicación, lo que te permite tener un único proveedor para todas tus necesidades. Nuestra propuesta es completa, abarcando el desarrollo e implementación de estrategias de marketing, presencia en redes sociales, publicidad en diferentes plataformas, diseño web, producción de contenido audiovisual y organización de eventos corporativos. Adaptamos nuestros servicios según tus necesidades, ofreciendo planes mensuales todo incluido o servicios específicos de manera independiente.",
      color: "#963aeb",
    },
    {
      question: "¿Qué tipo de material audiovisual podemos crear?",
      answer:
        "Somos una agencia de comunicación y productora audiovisual con capacidad para crear una amplia gama de contenidos. Desde vídeos institucionales y anuncios publicitarios, hasta animaciones, entrevistas, tutoriales y producciones en estudio.",
      color: "#002b61",
    },
    {
      question: "¿Existe un período mínimo de compromiso?",
      answer:
        "No requerimos un período mínimo de compromiso. Al contratar nuestros servicios, tienes la libertad de cancelar en cualquier momento. Sin embargo, para lograr resultados significativos para tu negocio, recomendamos una colaboración mínima de 3 meses. Esto nos permite sumergirnos en tu proyecto y comprender mejor la dinámica de trabajo cotidiana.",
      color: "#ff1360",
    },
    {
      question:
        "¿Cuál es la diferencia entre contratar un Community Manager o una agencia?",
      answer:
        "Las diferencias son muchas, pero a modo de resumen: El Community Manager, conocido como CM, se centra en la gestión y mantenimiento diario de las redes sociales. Sin embargo, una agencia integral como Bondi ofrece un enfoque más completo al analizar datos, diseñar estrategias a medio y largo plazo para conectar con la audiencia, evaluar el estado y enfoque de la marca, así como monitorear su reputación online. En Bondi, cada equipo dedicado a nuestros clientes, dependiendo del plan o servicio contratado, está compuesto por profesionales especializados en el sector: Social Media Manager, Ejecutivo de cuentas, Community Manager, Diseñadores gráficos y audiovisuales, y Growth Marketer.",
      color: "#ffdb08",
    },
    {
      question: "¿Las redes sociales generan ventas?",
      answer:
        "En principio, no. Las redes sociales son herramientas de comunicación que facilitan la conexión y la construcción de confianza con tu audiencia. Sin embargo, estas relaciones sólidas pueden conducir a ventas. Con una estrategia de redes sociales efectiva, es posible cerrar ventas, entablar conversaciones con clientes potenciales y convertirlos en clientes reales.",
      color: "#963aeb",
    },
    {
      question:
        "¿Cuál es el costo de contratar una agencia de Marketing Digital?",
      answer:
        "Los costos pueden variar según la estructura y los servicios ofrecidos por cada agencia. Adaptamos nuestros planes para satisfacer las necesidades específicas de cada cliente.",
      color: "#002b61",
    },
    {
      question: "¿Cuánto tiempo se necesita para comenzar?",
      answer:
        "Una vez contratados nuestros servicios, necesitaremos entre 1 y 4 semanas para comenzar, dependiendo de varios factores como la existencia de una identidad gráfica establecida, la definición del brief, la aprobación oportuna, la coordinación de sesiones de grabación y la planificación estratégica. Es fundamental para nosotros comprender a fondo tu negocio y tu mercado objetivo.",
      color: "#ff1360",
    },
  ],
}) => {
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = (questionIndex) => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  return (
    <Container>
      <ContentWithPaddingXl>
        <Column>
          <HeaderContent>
            {/* {subheading && <Subheading>{subheading}</Subheading>} */}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
          </HeaderContent>
          <FAQSContainer>
            {faqs.map((faq, index) => (
              <FAQ
                key={index}
                onClick={() => {
                  toggleQuestion(index);
                }}
                className="group"
              >
                <Question>
                  <QuestionText>{faq.question}</QuestionText>
                  <QuestionToggleIcon
                    variants={{
                      collapsed: { rotate: 0 },
                      open: { rotate: -180 },
                    }}
                    initial="collapsed"
                    animate={
                      activeQuestionIndex === index ? "open" : "collapsed"
                    }
                    transition={{
                      duration: 0.02,
                      ease: [0.04, 0.62, 0.23, 0.98],
                    }}
                  >
                    <DecoratorChevronDown color={faq.color} />
                  </QuestionToggleIcon>
                </Question>
                <Answer
                  variants={{
                    open: { opacity: 1, height: "auto", marginTop: "16px" },
                    collapsed: { opacity: 0, height: 0, marginTop: "0px" },
                  }}
                  initial="collapsed"
                  animate={activeQuestionIndex === index ? "open" : "collapsed"}
                  transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                >
                  {faq.answer}
                </Answer>
              </FAQ>
            ))}
          </FAQSContainer>
        </Column>
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
