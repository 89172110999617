import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Container as ContainerBase } from "components/misc/Layouts.js";

import logo from "images/imagesBondi/LOGO CALADO NEGRO.png";
import { ReactComponent as FacebookIcon } from "../../images/whatsapp.svg";
import { ReactComponent as TwitterIcon } from "../../images/instagram.svg";
import { ReactComponent as YoutubeIcon } from "../../images/linkedin-in.svg";

const Container = tw(
  ContainerBase
)`bg-gray-900 text-gray-100 -mx-8 -mb-8 bg-customGrey`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const Row = tw.div`flex items-center justify-center flex-col px-8`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-40 mr-3`;
const LogoText = tw.h5`ml-2 text-2xl font-black tracking-wider`;

const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`;
const Link = tw.a`border-b-2 border-transparent text-black hocus:text-customPurple hocus:border-customPurple pb-1 transition duration-300 mt-2 mx-4`;

const SocialLinksContainer = tw.div`mt-10`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-black hover:text-customPurple transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`;
export default ({}) => {
  const scrollToSection = (id) => {
    console.log("working");
    const section = document.getElementById(id);
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: "smooth",
      });
    }
  };
  // const navLinks = [
  //   <NavLinks key={1}>
  //     <NavLink onClick={() => scrollToSection("welcome")}>BIENVENIDOS</NavLink>
  //     <NavLink onClick={() => scrollToSection("servicios")}>SERVICIOS</NavLink>
  //     <NavLink onClick={() => scrollToSection("about")}>QUIENES SOMOS</NavLink>
  //     <NavLink onClick={() => scrollToSection("plans")}>PLANES</NavLink>
  //     <NavLink onClick={() => scrollToSection("contact")}>CONTACTO</NavLink>
  //   </NavLinks>,
  // ];
  return (
    <Container>
      <Content>
        <Row>
          <LogoContainer>
            <LogoImg src={logo} />
          </LogoContainer>

          <LinksContainer>
            <Link onClick={() => scrollToSection("welcome")}>Bienvenidos</Link>
            <Link onClick={() => scrollToSection("servicios")}>Servicios</Link>
            <Link onClick={() => scrollToSection("about")}>¿Qué hacemos?</Link>
            <Link onClick={() => scrollToSection("plans")}>Planes</Link>
            <Link onClick={() => scrollToSection("contact")}>Contacto</Link>
          </LinksContainer>
          <SocialLinksContainer>
            <SocialLink href="https://wa.me/34604869227?text=Que tal? Queria pedir cotizacion.">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://www.instagram.com/bondi.marketingdigital?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==">
              <TwitterIcon />
            </SocialLink>
            <SocialLink href="https://www.linkedin.com/company/bondi-digital">
              <YoutubeIcon />
            </SocialLink>
          </SocialLinksContainer>
          <CopyrightText>
            &copy; Copyright 2024, Bondi Digital. All Rights Reserved.
          </CopyrightText>
        </Row>
      </Content>
    </Container>
  );
};
