// import React from "react";
// import tw from "twin.macro";
// import styled from "styled-components";
// import { css } from "styled-components/macro"; //eslint-disable-line
// import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-9.svg";
// import { ContentWithPaddingXl } from "components/misc/Layouts";
// import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

// const Container = styled.div`
//   ${tw`relative -mx-8  bg-center bg-cover bg-black `}
//   background-color: black);
//   background-size: cover;
// `;

// const PrimaryBackgroundContainer = tw.div`py-16 lg:py-20 rounded-lg relative`;
// const Row = tw.div`px-4 sm:px-16 mx-auto flex justify-center items-center relative z-10 flex-col  text-center lg:text-left`;

// const ColumnContainer = tw.div`lg:w-1/2 max-w-lg`;
// const TextContainer = tw(ColumnContainer)`text-2xl sm:text-4xl font-bold`;
// const Subheading = tw.h6`text-primary-500 text-white`;
// const Heading = tw.h5`text-primary-500`;

// const LinksContainer = tw(
//   ColumnContainer
// )`flex justify-center mt-6 lg:mt-0 flex-col sm:flex-row`;

// const PrimaryLink = tw(
//   PrimaryButtonBase
// )` font-ginestra mt-8 inline-block w-56 tracking-wide text-xl text-center py-5 bg-customPurple text-white`;

// // const SecondaryLink = tw(
// //   Link
// // )`text-primary-500 hover:text-primary-600 bg-gray-100 hover:bg-gray-200`;

// const DecoratorBlobContainer = tw.div`absolute inset-0 overflow-hidden rounded-lg`;
// const DecoratorBlob1 = tw(
//   SvgDecoratorBlob1
// )`absolute bottom-0 left-0 w-80 h-80 transform -translate-x-20 translate-y-32 text-primary-500 opacity-5`;
// const DecoratorBlob2 = tw(
//   SvgDecoratorBlob1
// )`absolute top-0 right-0 w-80 h-80 transform  translate-x-20 -translate-y-64 text-primary-500 opacity-5`;
// export default ({
//   subheading = "¿Quieres una auditoría GRATUITA?",

//   primaryLinkText = "¡ESCRIBENOS!",
//   primaryLinkUrl = "http://timerse.com",
//   secondaryLinkText = "Escribenos",
//   secondaryLinkUrl = "https://wa.me/34604869227",
//   buttonRounded = true,
//   pushDownFooter = true,
// }) => {
//   const buttonRoundedCss = buttonRounded && tw`rounded-full`;
//   return (
//     <Container css={pushDownFooter && tw`mb-20 lg:mb-24`}>
//       <PrimaryBackgroundContainer>
//         <Row>
//           <TextContainer>
//             {subheading && <Subheading>{subheading}</Subheading>}
//           </TextContainer>
//           <LinksContainer>
//             <PrimaryLink href={primaryLinkUrl} css={buttonRoundedCss}>
//               {primaryLinkText}
//             </PrimaryLink>
//             {/* <SecondaryLink href={secondaryLinkUrl}>
//               {secondaryLinkText}
//             </SecondaryLink> */}
//           </LinksContainer>
//         </Row>
//         <DecoratorBlobContainer>
//           <DecoratorBlob1 />
//           <DecoratorBlob2 />
//         </DecoratorBlobContainer>
//       </PrimaryBackgroundContainer>
//     </Container>
//   );
// };

import estrellaImg from "../../images/ROMBO COLOR.png"; // Ajusta la ruta según sea necesario

import React from "react";
import tw from "twin.macro";
import styled, { keyframes } from "styled-components";
import { ContentWithPaddingXl } from "components/misc/Layouts";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

const Container = styled.div`
  ${tw`relative -mx-8 bg-center bg-cover bg-black`}
  background-color: black;
  background-size: cover;
`;

const PrimaryBackgroundContainer = tw.div`py-16 lg:py-20 rounded-lg relative`;
const Row = tw.div`px-4 sm:px-16 mx-auto flex justify-center items-center relative z-10 flex-col text-center lg:text-left`;

const ColumnContainer = tw.div`lg:w-1/2 max-w-lg`;
const TextContainer = tw(ColumnContainer)`text-2xl sm:text-4xl font-bold`;
const Subheading = tw.h6`text-primary-500 text-white`;
const Heading = tw.h5`text-primary-500`;

const LinksContainer = tw(
  ColumnContainer
)`flex justify-center mt-6 lg:mt-0 flex-col sm:flex-row`;

const PrimaryLink = tw(
  PrimaryButtonBase
)`font-ginestra mt-8 inline-block w-56 tracking-wide text-xl text-center py-5 bg-customPurple text-white rounded-full`;

export default ({
  subheading = "¿Quieres una auditoría GRATUITA?",
  primaryLinkText = "¡ESCRÍBENOS!",
  primaryLinkUrl = "https://wa.me/34604869227",
  buttonRounded = true,
  pushDownFooter = true,
}) => {
  const buttonRoundedCss = buttonRounded && tw`rounded-full`;
  return (
    <Container css={pushDownFooter && tw`mb-20 lg:mb-24`}>
      <PrimaryBackgroundContainer>
        <Row>
          <TextContainer>
            {subheading && <Subheading>{subheading}</Subheading>}
          </TextContainer>
          <LinksContainer>
            <PrimaryLink href={primaryLinkUrl} css={buttonRoundedCss}>
              {primaryLinkText}
            </PrimaryLink>
          </LinksContainer>
        </Row>
      </PrimaryBackgroundContainer>
    </Container>
  );
};
