import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

import { useRef } from "react";
import emailjs from "emailjs-com";

import { css } from "styled-components/macro"; //eslint-disable-line
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import fondoForm from "../../images/fondo.png";
import fondo from "../../images/fondoForm2.PNG";
import logo from "../../images/imagesBondi/LOGO_CALADO BLANCO.png";

const Container = styled.div`
relative
;`;
const TwoColumn1 = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto `;
const Column1 = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column1)`md:w-7/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column1)`
  ${tw`md:w-5/12 mt-16 md:mt-0`}

  ${(props) =>
    props.textOnLeft
      ? tw`md:mr-12 lg:mr-16 md:order-first`
      : tw`md:ml-12 lg:ml-16 md:order-last`}
`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const FormContainer = styled.div`
  ${tw`p-10 sm:p-12 md:p-16 bg-primary-500 text-gray-100 rounded-lg relative`}
  background-image: url(${fondo});
  background-size: cover; /* Ajusta el tamaño de la imagen para cubrir el contenedor */
  background-position: center; /* Centra la imagen */
   img{
    ${tw`w-56 mr-3`}
   }
  form {
    ${tw`mt-4 p-4 rounded-lg`}
    background-image: url(${fondoForm});
    
    background-size: cover;
    
    background-position: center;}
  }
  h2 {
    ${tw`text-5xl sm:text-5xl mt-32 text-center font-bold text-white font-ginestra`}
  }
  h3 {
    ${tw` text-xl text-black font-bold font-ginestra text-center text-white`}
  }

  input,
  textarea {
    ${tw`w-full bg-transparent text-black text-base font-medium tracking-wide border-b-2 border-white  text-black hocus:border-pink-400 focus:outline-none transition duration-200 font-ddin`};

    ::placeholder {
      ${tw`text-black font-bold`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const Column = tw.div` flex flex-col`;
const InputContainer = tw.div`relative py-5`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold font-ddin text-sm`;
const Input = tw.input``;
const TextArea = tw.textarea`h-24 sm:h-full resize-none`;
const SubmitButtonContainer = tw.div`flex justify-center mt-6`;
const SubmitButton = tw.button`w-full sm:w-32 mt-6 py-3 bg-gray-100 text-primary-500 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl text-white bg-customPurple`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 -z-10 opacity-50 text-primary-500 fill-current w-24`;

export default () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_2peullf",
        "template_33146nk",
        form.current,
        "RWO7uxF-zmQWtTVxj"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    e.target.reset();
  };

  return (
    <Container id="contact">
      <Content>
        <FormContainer>
          <TwoColumn1>
            <ImageColumn>
              <img src={logo} alt="logo" />
              <h2>
                ¡ESCRÍBENOS! <br></br>Nos encantará ayudarte
              </h2>
            </ImageColumn>
            <TextColumn>
              <div tw="mx-auto max-w-4xl">
                <form action="#" ref={form} onSubmit={sendEmail}>
                  <h3>
                    ¿Necesitas potenciar tu comunicación digital?<br></br>
                    ¡Cuenta con nosotros!
                  </h3>

                  <Column>
                    <InputContainer>
                      <Label htmlFor="name-input">Nombre</Label>
                      <Input
                        id="name-input"
                        type="text"
                        name="user_name"
                        placeholder="Juan Perez"
                      />
                    </InputContainer>
                    <InputContainer>
                      <Label htmlFor="email-input">Correo electronico</Label>
                      <Input
                        id="email-input"
                        type="email"
                        name="user_email"
                        placeholder="juan@mail.com"
                      />
                    </InputContainer>

                    <InputContainer>
                      <Label htmlFor="ig-input">Instagram</Label>
                      <Input
                        id="name-input"
                        type="text"
                        name="ig"
                        placeholder="@juanperez"
                      />
                    </InputContainer>
                    <InputContainer>
                      <Label htmlFor="web-input">Pagina web</Label>
                      <Input
                        id="name-input"
                        type="text"
                        name="web"
                        placeholder="www.tupaginaweb.es"
                      />
                    </InputContainer>
                    <InputContainer>
                      <Label htmlFor="name-input">Mensaje</Label>
                      <TextArea
                        id="message-input"
                        name="message"
                        placeholder="Solicito cotizacion mensual"
                      />
                    </InputContainer>
                  </Column>

                  <SubmitButtonContainer>
                    <SubmitButton type="submit" value="Submit">
                      Enviar
                    </SubmitButton>
                  </SubmitButtonContainer>
                </form>
              </div>
              <SvgDotPattern1 />
            </TextColumn>
          </TwoColumn1>
        </FormContainer>
      </Content>
    </Container>
  );
};
