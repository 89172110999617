import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

import desarrolloWeb from "images/DESARROLLO WEB.png";
import edicionAudiovisual from "images/DISEÑO AUDIOVISUAL.png";
import diseñoGrafico from "images/DISEÑO GRAFICO.png";
import diseñoDigital from "images/DISEÑO DIGITAL.png";
import campañasPublicitarias from "images/CAMPAÑAS PUBLICITARIAS.png";
import EstrategiaSocial from "images/ESTRATEGIA SOCIAL MEDIA.png";
import ShieldIconImage from "images/shield-icon.svg";

import CustomizeIconImage from "images/customize-icon.svg";
import FastIconImage from "images/fast-icon.svg";
import ReliableIconImage from "images/reliable-icon.svg";
import SimpleIconImage from "images/simple-icon.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-0`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(
  SectionDescription
)`font-ddin w-full font-bold text-center text-black`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full   py-8 transition-transform duration-300 hover:cursor-pointer transform hover:scale-105`}
  .imageContainer {
    ${tw`border text-center rounded-full p-3 flex-shrink-0`}
    img {
      ${tw`w-10 h-10 `}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-6 my-6 pb-6 mb-6 tracking-wide sm:mb-6 sm:pb-6 font-ddin font-bold text-2xl leading-none text-customBlue`}
  }
`;
const Descriptions = styled.div`
  ${tw`flex flex-col -mx-8 px-8 py-8 flex-1 text-sm`}
  .description {
    ${tw`mt-5 first:mt-0 pl-2 text-black sm:mt-1 font-ddin font-medium leading-loose font-bold`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({
  cards = null,
  heading = "¿QUÉ HACEMOS?",
  // subheading = "Features",
  description = "Esto es todo lo que nuestro equipo puede hacer, y armaremos un plan a tu medida con todo lo que necesites para que tu comunicación digital sea %100 increíble.",
}) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    {
      imageSrc: EstrategiaSocial,
      title: "Estrategia",
      description: [
        " Auditorías y asesorías",
        " Creación de plan de comunicación",
        " Estrategia de contenidos",
        " Desarrollo de procesos de comunicación interno",
        " Desarrollo de manual de tono y voz para moderación",
        " Gestion de redes",
      ],
    },
    {
      imageSrc: edicionAudiovisual,
      title: "Edición audiovisual",
      description: [
        " Videos institucionales",
        " Videos de contenido para redes",
        " Fotografía",
        " Retoque fotográfico",
        " Animación y Motion Graphic",
      ],
    },
    {
      imageSrc: desarrolloWeb,
      title: "Desarrollo Web",
      description: [
        " Paginas e-commerce",
        " Paginas autoadministrables",
        " Paginas institucionales y de servicios",
        " Paginas de aterrizaje o lanidng",
        " Paginas para anuncios",
        " Paginas para la realización de cursos",
      ],
    },
    {
      imageSrc: diseñoGrafico,
      title: "Diseño grafico",
      description: [
        " Branding",
        " Flyer y Folletos",
        " Marquesinas",
        " Vinilicos",
        " Cartelería para Vía publica",
        " Diseño de Packaging",
      ],
    },
    {
      imageSrc: diseñoDigital,
      title: "Diseño digital",
      description: [
        " Key Visual de comunicación digital",
        " Diseño para web",
        " Grilla de contenidos",
        " Identidad digital",
        " E-mail marketing",
      ],
    },
    {
      imageSrc: campañasPublicitarias,
      title: "Campañas publicitarias",
      description: [
        " Lineamientos con KPIS",
        " Presentación de reportes dinámicos",
        " Auditorías y asesorías",
        " Gestion de campañas publicitarias de Meta, Google, TikTok, YouTube",
      ],
    },
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container id="about">
      <ThreeColumnContainer>
        {/* {subheading && <Subheading>{subheading}</Subheading>} */}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <Descriptions>
                  {card.description.map((description, index) => (
                    <span key={index} className="description">
                      &bull; {description}
                    </span>
                  ))}
                </Descriptions>
                {/* <li className="description">
                    {card.description ||
                      "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                      {plan.features.map((feature, index) => (
                  <span key={index} className="feature">
                    {feature}
                  </span>
                ))}
                  </li> */}
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
