import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-6.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";

const HeaderContainer = tw.div`w-full flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(
  SectionDescription
)`font-ddin w-full font-bold text-black text-center`;

const PlanDurationSwitcher = tw.div`block w-full max-w-xs sm:inline-block sm:w-auto border-2 rounded-full px-1 py-1 mt-8`;

const PlansContainer = tw.div`flex justify-center flex-col md:flex-row items-center md:items-start relative`;
const Plan = styled.div`
  ${tw`w-full max-w-72 mt-16 md:mr-12 md:last:mr-0 text-center px-8 rounded-lg relative text-gray-900 bg-white flex flex-col shadow-raised`}

  ${(props) =>
    props.featured &&
    css`
      ${tw`border-2 border-gray-200 shadow-none`}
    `}
`;

const PlanHeader = styled.div`
  ${tw`flex flex-col leading-relaxed py-8 -mx-8 bg-customRed rounded-t-lg`}
  .name {
    ${tw`font-bold text-xl text-customYellow `}
  }
  .price {
    ${tw`font-bold text-4xl sm:text-5xl my-1`}
  }
  .slash {
    ${tw`text-xl text-gray-500`}
  }
  .duration {
    ${tw`lowercase text-gray-500 font-medium tracking-widest`}
  }
  .mainFeature {
    ${tw`text-gray-500 text-sm font-medium tracking-wide`}
  }
`;
const PlanFeatures = styled.div`
  ${tw`flex flex-col mt-3 -mx-8 px-2 py-2 flex-1 text-sm`}
  .feature {
    ${tw`mt-2 first:mt-0 font-semibold text-black`}
  }
`;

// const PlanAction = tw.div`px-4 pb-8`;

const SwitchButton = styled.button`
  ${tw`mt-6 px-4 sm:px-8 py-3 rounded-full focus:outline-none text-sm font-bold font-ginestra text-gray-700 bg-customRed text-customYellow`}
`;
const BuyNowButton = styled(PrimaryButtonBase)`
  ${tw`rounded-full my-5 tracking-wider py-4 w-full text-sm hover:shadow-xl transform hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline bg-customRed text-customYellow`}
`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-25 transform -translate-x-2/3 -translate-y-1/2`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-25 transform translate-x-2/3 translate-y-1/2 fill-current text-teal-300`}
`;

export default ({
  // subheading = "Pricing",
  heading = "Flexible Plans.",
  description = "Podemos hacer planes personalizados o sumar servicios a nuestros planes ya armados.  ¡Recuerda que lo más importante para nosotros es que tu marca sea increíble!",
  plans = null,
  primaryButtonUrl = `https://wa.me/34604869227?text=Que tal? Queria pedir cotizacion.`,
  primaryButtonText = "CONTACTANOS",
  planDurations = "¡HABLEMOS!",
}) => {
  const defaultPlans = [
    {
      name: "PLAN 1",
      // durationPrices: ["$49", "$499"],
      // mainFeature: "Suited for Production Websites",
      features: [
        "CM y Diseñador",
        "Diseño y calendarización de grilla",
        "Estrategia de contenidos",
        "Reportes mensuales",
        "Optimización y diseño de bio",
        "Key visual",
      ],
    },
    {
      name: "PLAN 2",
      // durationPrices: ["$0", "$0"],
      // mainFeature: "For Personal Blogs",
      features: [
        "CM, Diseñador y SMM",

        "Incluye todo del Plan 1, más:",
        "Diseño audiovisual",
        "Gestión de campañas",
        " ",
        " ",
        " ",
        " ",
        " ",
        " ",
        " ",
      ],
    },

    {
      name: "PLAN 3",
      // durationPrices: ["$0", "$0"],
      // mainFeature: "For Personal Blogs",
      features: [
        "CM, Ejecutivo de Cuentas, Diseñador y SMM",

        "Incluye todo del Plan 1, más:",
        "identidad de marca digital",
        "Estrategia de comunicación",
        "Descuentos en otros servicios",
        " ",
      ],
    },
    {
      name: "PLAN 4",
      // durationPrices: ["$0", "$0"],
      // mainFeature: "For Personal Blogs",
      features: [
        "En este plan, adaptamos nuestros servicios a tus necesidades específicas para crear un plan personalizado que se ajuste perfectamente a tus objetivos y presupuesto",
        " ",
        " ",
        " ",
        " ",
        " ",
      ],
    },
    // {
    //   name: "Plan Personalizado",
    //   durationPrices: ["", ""],
    //   mainFeature: "Suited for Production Websites",
    //   features: [
    //     "60 Templates",
    //     "8 Landing Pages",
    //     "22 Internal Pages",
    //     "Priority Assistance",
    //     "Lifetime Updates",
    //   ],
    //   featured: true,
    // },
  ];

  if (!plans) plans = defaultPlans;

  const [activeDurationIndex, setActiveDurationIndex] = useState(0);

  return (
    <Container id="plans">
      <ContentWithPaddingXl>
        <HeaderContainer>
          {/* {subheading && <Subheading>{subheading}</Subheading>} */}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}

          <SwitchButton as="a" href={primaryButtonUrl}>
            {planDurations}
          </SwitchButton>
        </HeaderContainer>
        <PlansContainer>
          {plans.map((plan, index) => (
            <Plan key={index} featured={plan.featured}>
              <PlanHeader>
                <span className="priceAndDuration">
                  {/* <span className="price">
                    {plan.durationPrices[activeDurationIndex]}
                  </span> */}
                  {/* <span className="slash"> / </span> */}
                  <span className="duration">
                    {planDurations[activeDurationIndex].text}
                  </span>
                </span>
                <span className="name">{plan.name}</span>
                <span className="mainFeature">{plan.mainFeature}</span>
              </PlanHeader>
              <PlanFeatures>
                {plan.features.map((feature, index) => (
                  <span key={index} className="feature">
                    {feature}
                  </span>
                ))}
              </PlanFeatures>
              {/* <PlanAction> */}
              <BuyNowButton as="a" href={primaryButtonUrl}>
                {primaryButtonText}
              </BuyNowButton>
              {/* </PlanAction> */}
            </Plan>
          ))}
        </PlansContainer>
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
