import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { SectionHeading } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import Header, {
  NavLink,
  NavLinks,
  PrimaryLink,
  LogoLink,
  NavToggle,
  DesktopNavLinks,
} from "../headers/light.js";
// import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";
import fondoMain from "../../images/banner.jpg";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-black hover:border-customPurple hover:text-customPurple`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover `}
  background-image: url(${fondoMain});
  background-size: cover;
`;

// const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-primary-500 opacity-25`;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;

const Content = tw.div` px-4 flex flex-1 flex-col 
justify-center items-center pt-32 pb-32`;

const TwoColumn = tw.div`pt-24 pb-32 px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`flex flex-col items-center lg:block`;
const RightColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8`;

// const Heading = styled.h1`
//   ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-none`}
//   span {
//     ${tw`inline-block mt-2`}
//   }
// `;

const SlantedBackground = styled.span`
  ${tw`relative text-primary-500 px-4 -mx-4 py-2`}
  &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`}
  }
`;

const Notification = tw(
  SectionDescription
)`font-ddin text-center mt-4 lg:text-base  max-w-lg text-black inline-block my-4 pl-3 py-1  font-medium text-sm`;

const PrimaryAction = tw.button`px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 text-primary-500 font-bold rounded shadow transition duration-300 hocus:bg-primary-500 hocus:text-gray-100 focus:shadow-outline`;

const PrimaryButton = tw(
  PrimaryButtonBase
)` font-ginestra mt-8 inline-block w-56 tracking-wide text-center py-5 bg-customYellow text-customRed`;

const Heading = tw(
  SectionHeading
)`font-ginestra text-3xl text-center sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-snug -mt-24 sm:mt-0 text-black`;

const Description = tw(
  SectionDescription
)`font-ddin mt-4 lg:text-base text-gray-700 max-w-lg text-white`;
// const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
//   padding-bottom: 56.25% !important;
//   padding-top: 0px !important;
//   ${tw`rounded`}
//   iframe {
//     ${tw`rounded bg-black shadow-xl`}
//   }
// `;

export default ({
  heading = "DIGITALIZÁ ",
  heading2 = "TU COMUNICACIÓN.",
  notification = "Agencia creativa de marketing y comunicación.  Con  estrategias 360, lograremos que tu marca llegue más allá.",
  imageSrc = "https://images.unsplash.com/photo-1573496359142-b8d87734a5a2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80",
  imageDecoratorBlob = true,
  primaryButtonUrl = `https://wa.me/541138069588?text=Que tal? Queria pedir cotizacion.`,
  primaryButtonText = "¡CONTACTA!",
  buttonRounded = true,
  // features = [
  //   "Available in 7 Locations",
  //   "Premium Internet Backbone",
  //   "99.99% Uptime SLA",
  // ],
}) => {
  const buttonRoundedCss = buttonRounded && tw`rounded-full`;
  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: "smooth",
      });
    }
  };
  const navLinks = [
    <NavLinks key={1}>
      <NavLink onClick={() => scrollToSection("welcome")}>BIENVENIDOS</NavLink>
      <NavLink onClick={() => scrollToSection("services")}>SERVICIOS</NavLink>
      <NavLink onClick={() => scrollToSection("about")}>QUIENES SOMOS</NavLink>
      <NavLink onClick={() => scrollToSection("plans")}>PLANES</NavLink>
      <NavLink onClick={() => scrollToSection("contact")}>CONTACTO</NavLink>
    </NavLinks>,
  ];

  return (
    <Container>
      {/* <OpacityOverlay /> */}
      <HeroContainer>
        <StyledHeader links={navLinks} />
        <Content id="welcome">
          {/* <TwoColumn>
          <LeftColumn> */}
          <Heading>
            {heading}

            <br />
            {heading2}
            {/* <SlantedBackground></SlantedBackground> */}
          </Heading>

          <Notification>{notification}</Notification>
          <PrimaryButton as="a" href={primaryButtonUrl} css={buttonRoundedCss}>
            {primaryButtonText}
          </PrimaryButton>
          {/* </LeftColumn>
          <RightColumn> */}
          {/* <StyledResponsiveVideoEmbed
              url="//player.vimeo.com/video/374265101?title=0&portrait=0&byline=0&autoplay=0&responsive=1"
              background="transparent"
            /> */}
          {/* </RightColumn>
        </TwoColumn> */}
        </Content>
      </HeroContainer>
    </Container>
  );
};
